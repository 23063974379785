/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiContentResponseModel, InlineResponse200, InlineResponse2001 } from '@portal/model';
import { BASE_PATH } from '@portal/common';
import { Configuration } from '@portal/configs';
import { CustomHttpUrlEncodingCodec } from '@portal/utils';
import { environment } from 'src/environments/environment';


@Injectable()
export class ContentService {

  protected basePath = environment.umbracoBaseUri;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * 
   * 
   * @param fetch Specifies the content items to fetch. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param filter Defines how to filter the fetched content items. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param sort Defines how to sort the found content items. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param skip Specifies the number of found content items to skip. Use this to control pagination of the response.
   * @param take Specifies the number of found content items to take. Use this to control pagination of the response.
   * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param acceptLanguage Defines the language to return. Use this when querying language variant content items.
   * @param apiKey API key specified through configuration to authorize access to the API.
   * @param preview Whether to request draft content.
   * @param startItem URL segment or GUID of a root content item.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContent20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
  public getContent20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
  public getContent20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
  public getContent20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fetch !== undefined && fetch !== null) {
      queryParameters = queryParameters.set('fetch', <any>fetch);
    }
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      })
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }
    if (skip !== undefined && skip !== null) {
      queryParameters = queryParameters.set('skip', <any>skip);
    }
    if (take !== undefined && take !== null) {
      queryParameters = queryParameters.set('take', <any>take);
    }
    if (expand !== undefined && expand !== null) {
      queryParameters = queryParameters.set('expand', <any>expand);
    }
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('Api-Key', String(apiKey));
    }
    if (preview !== undefined && preview !== null) {
      headers = headers.set('Preview', String(preview));
    }
    if (startItem !== undefined && startItem !== null) {
      headers = headers.set('Start-Item', String(startItem));
    }




    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse200>('get', `${this.basePath}/umbraco/delivery/api/v2/content`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * 
   * 
   * @param id 
   * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param acceptLanguage Defines the language to return. Use this when querying language variant content items.
   * @param apiKey API key specified through configuration to authorize access to the API.
   * @param preview Whether to request draft content.
   * @param startItem URL segment or GUID of a root content item.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContentItemById20(id: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
  public getContentItemById20(id: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
  public getContentItemById20(id: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
  public getContentItemById20(id: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getContentItemById20.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (expand !== undefined && expand !== null) {
      queryParameters = queryParameters.set('expand', <any>expand);
    }
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('Api-Key', String(apiKey));
    }
    if (preview !== undefined && preview !== null) {
      headers = headers.set('Preview', String(preview));
    }
    if (startItem !== undefined && startItem !== null) {
      headers = headers.set('Start-Item', String(startItem));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse2001>('get', `${this.basePath}/umbraco/delivery/api/v2/content/item/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * 
   * 
   * @param path 
   * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param acceptLanguage Defines the language to return. Use this when querying language variant content items.
   * @param apiKey API key specified through configuration to authorize access to the API.
   * @param preview Whether to request draft content.
   * @param startItem URL segment or GUID of a root content item.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContentItemByPath20(path: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
  public getContentItemByPath20(path: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
  public getContentItemByPath20(path: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
  public getContentItemByPath20(path: string, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (path === null || path === undefined) {
      throw new Error('Required parameter path was null or undefined when calling getContentItemByPath20.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (expand !== undefined && expand !== null) {
      queryParameters = queryParameters.set('expand', <any>expand);
    }
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('Api-Key', String(apiKey));
    }
    if (preview !== undefined && preview !== null) {
      headers = headers.set('Preview', String(preview));
    }
    if (startItem !== undefined && startItem !== null) {
      headers = headers.set('Start-Item', String(startItem));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse2001>('get', `${this.basePath}/umbraco/delivery/api/v2/content/item/${encodeURIComponent(String(path))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * 
   * 
   * @param id 
   * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api#query-parameters) for more details on this.
   * @param acceptLanguage Defines the language to return. Use this when querying language variant content items.
   * @param apiKey API key specified through configuration to authorize access to the API.
   * @param preview Whether to request draft content.
   * @param startItem URL segment or GUID of a root content item.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContentItems20(id?: Array<string>, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApiContentResponseModel>>;
  public getContentItems20(id?: Array<string>, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiContentResponseModel>>>;
  public getContentItems20(id?: Array<string>, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiContentResponseModel>>>;
  public getContentItems20(id?: Array<string>, expand?: string, fields?: string, acceptLanguage?: string, apiKey?: string, preview?: boolean, startItem?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id) {
      id.forEach((element) => {
        queryParameters = queryParameters.append('id', <any>element);
      })
    }
    if (expand !== undefined && expand !== null) {
      queryParameters = queryParameters.set('expand', <any>expand);
    }
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('Api-Key', String(apiKey));
    }
    if (preview !== undefined && preview !== null) {
      headers = headers.set('Preview', String(preview));
    }
    if (startItem !== undefined && startItem !== null) {
      headers = headers.set('Start-Item', String(startItem));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<ApiContentResponseModel>>('get', `${this.basePath}/umbraco/delivery/api/v2/content/items`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
