import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from '@portal/configs';
import { HttpClient } from '@angular/common/http';
import { UmbracoServices } from '.';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: UmbracoServices
})
export class UmbracoServiceModule {

  constructor(@Optional() @SkipSelf() parentModule: UmbracoServiceModule,
    @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
