/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@portal/utils';
import { Observable } from 'rxjs';
import { ApiMediaWithCropsResponseModel, InlineResponse2002, InlineResponse2003 } from '@portal/model';
import { BASE_PATH } from '@portal/common';
import { Configuration } from '@portal/configs';


@Injectable()
export class MediaService {
    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param fetch Specifies the media items to fetch. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param filter Defines how to filter the fetched media items. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param sort Defines how to sort the found media items. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param skip Specifies the number of found media items to skip. Use this to control pagination of the response.
     * @param take Specifies the number of found media items to take. Use this to control pagination of the response.
     * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param apiKey API key specified through configuration to authorize access to the API.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMedia20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, apiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public getMedia20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, apiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public getMedia20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, apiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public getMedia20(fetch?: string, filter?: Array<string>, sort?: Array<string>, skip?: number, take?: number, expand?: string, fields?: string, apiKey?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (fetch !== undefined && fetch !== null) {
            queryParameters = queryParameters.set('fetch', <any>fetch);
        }
        if (filter) {
            filter.forEach((element) => {
                queryParameters = queryParameters.append('filter', <any>element);
            })
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (expand !== undefined && expand !== null) {
            queryParameters = queryParameters.set('expand', <any>expand);
        }
        if (fields !== undefined && fields !== null) {
            queryParameters = queryParameters.set('fields', <any>fields);
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('Api-Key', String(apiKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse2002>('get', `${this.basePath}/umbraco/delivery/api/v2/media`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param apiKey API key specified through configuration to authorize access to the API.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaItemById20(id: string, expand?: string, fields?: string, apiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2003>;
    public getMediaItemById20(id: string, expand?: string, fields?: string, apiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2003>>;
    public getMediaItemById20(id: string, expand?: string, fields?: string, apiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2003>>;
    public getMediaItemById20(id: string, expand?: string, fields?: string, apiKey?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMediaItemById20.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (expand !== undefined && expand !== null) {
            queryParameters = queryParameters.set('expand', <any>expand);
        }
        if (fields !== undefined && fields !== null) {
            queryParameters = queryParameters.set('fields', <any>fields);
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('Api-Key', String(apiKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse2003>('get', `${this.basePath}/umbraco/delivery/api/v2/media/item/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param path 
     * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param apiKey API key specified through configuration to authorize access to the API.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaItemByPath20(path: string, expand?: string, fields?: string, apiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2003>;
    public getMediaItemByPath20(path: string, expand?: string, fields?: string, apiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2003>>;
    public getMediaItemByPath20(path: string, expand?: string, fields?: string, apiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2003>>;
    public getMediaItemByPath20(path: string, expand?: string, fields?: string, apiKey?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (path === null || path === undefined) {
            throw new Error('Required parameter path was null or undefined when calling getMediaItemByPath20.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (expand !== undefined && expand !== null) {
            queryParameters = queryParameters.set('expand', <any>expand);
        }
        if (fields !== undefined && fields !== null) {
            queryParameters = queryParameters.set('fields', <any>fields);
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('Api-Key', String(apiKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse2003>('get', `${this.basePath}/umbraco/delivery/api/v2/media/item/${encodeURIComponent(String(path))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param expand Defines the properties that should be expanded in the response. Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param fields Explicitly defines which properties should be included in the response (by default all properties are included). Refer to [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api/media-delivery-api#query-parameters) for more details on this.
     * @param apiKey API key specified through configuration to authorize access to the API.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaItems20(id?: Array<string>, expand?: string, fields?: string, apiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApiMediaWithCropsResponseModel>>;
    public getMediaItems20(id?: Array<string>, expand?: string, fields?: string, apiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiMediaWithCropsResponseModel>>>;
    public getMediaItems20(id?: Array<string>, expand?: string, fields?: string, apiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiMediaWithCropsResponseModel>>>;
    public getMediaItems20(id?: Array<string>, expand?: string, fields?: string, apiKey?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id) {
            id.forEach((element) => {
                queryParameters = queryParameters.append('id', <any>element);
            })
        }
        if (expand !== undefined && expand !== null) {
            queryParameters = queryParameters.set('expand', <any>expand);
        }
        if (fields !== undefined && fields !== null) {
            queryParameters = queryParameters.set('fields', <any>fields);
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('Api-Key', String(apiKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiMediaWithCropsResponseModel>>('get', `${this.basePath}/umbraco/delivery/api/v2/media/items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
